export const countryListNum = {
    "phoneCodes": [
        {
            "isHidden": false,
            "iso2": "AF",
            "defaultName": "Afghanistan",
            "countryCode": "93",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AL",
            "defaultName": "Albania",
            "countryCode": "355",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DZ",
            "defaultName": "Algeria",
            "countryCode": "213",
            "patterns": [
                "XXX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AS",
            "defaultName": "American Samoa",
            "countryCode": "1684",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AD",
            "defaultName": "Andorra",
            "countryCode": "376",
            "patterns": [
                "XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AO",
            "defaultName": "Angola",
            "countryCode": "244",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AI",
            "defaultName": "Anguilla",
            "countryCode": "1264",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "FT",
            "defaultName": "Anonymous Numbers",
            "countryCode": "888",
            "patterns": [
                "8 XXX",
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AG",
            "defaultName": "Antigua & Barbuda",
            "countryCode": "1268",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AR",
            "defaultName": "Argentina",
            "countryCode": "54"
        },
        {
            "isHidden": false,
            "iso2": "AM",
            "defaultName": "Armenia",
            "countryCode": "374",
            "patterns": [
                "XX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AW",
            "defaultName": "Aruba",
            "countryCode": "297",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AU",
            "defaultName": "Australia",
            "countryCode": "61",
            "patterns": [
                "X XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AT",
            "defaultName": "Austria",
            "countryCode": "43",
            "patterns": [
                "XXX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AZ",
            "defaultName": "Azerbaijan",
            "countryCode": "994",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BS",
            "defaultName": "Bahamas",
            "countryCode": "1242",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BH",
            "defaultName": "Bahrain",
            "countryCode": "973",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BD",
            "defaultName": "Bangladesh",
            "countryCode": "880",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BB",
            "defaultName": "Barbados",
            "countryCode": "1246",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BY",
            "defaultName": "Belarus",
            "countryCode": "375",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BE",
            "defaultName": "Belgium",
            "countryCode": "32",
            "patterns": [
                "XXX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BZ",
            "defaultName": "Belize",
            "countryCode": "501"
        },
        {
            "isHidden": false,
            "iso2": "BJ",
            "defaultName": "Benin",
            "countryCode": "229",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BM",
            "defaultName": "Bermuda",
            "countryCode": "1441",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BT",
            "defaultName": "Bhutan",
            "countryCode": "975",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BO",
            "defaultName": "Bolivia",
            "countryCode": "591",
            "patterns": [
                "X XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BQ",
            "defaultName": "Bonaire, Sint Eustatius & Saba",
            "countryCode": "599"
        },
        {
            "isHidden": false,
            "iso2": "BA",
            "defaultName": "Bosnia & Herzegovina",
            "countryCode": "387",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BW",
            "defaultName": "Botswana",
            "countryCode": "267",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BR",
            "defaultName": "Brazil",
            "countryCode": "55",
            "patterns": [
                "XX XXXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "VG",
            "defaultName": "British Virgin Islands",
            "countryCode": "1284",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BN",
            "defaultName": "Brunei Darussalam",
            "countryCode": "673",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BG",
            "defaultName": "Bulgaria",
            "countryCode": "359"
        },
        {
            "isHidden": false,
            "iso2": "BF",
            "defaultName": "Burkina Faso",
            "countryCode": "226",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BI",
            "defaultName": "Burundi",
            "countryCode": "257",
            "patterns": [
                "XX XX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KH",
            "defaultName": "Cambodia",
            "countryCode": "855",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CM",
            "defaultName": "Cameroon",
            "countryCode": "237",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CA",
            "defaultName": "Canada",
            "countryCode": "1",
            "prefixes": [
                "403",
                "587",
                "780",
                "825",
                "236",
                "250",
                "604",
                "672",
                "778",
                "204",
                "431",
                "506",
                "709",
                "902",
                "782",
                "226",
                "249",
                "289",
                "343",
                "365",
                "416",
                "437",
                "519",
                "548",
                "613",
                "647",
                "705",
                "807",
                "905",
                "418",
                "438",
                "450",
                "514",
                "579",
                "581",
                "819",
                "873",
                "306",
                "639",
                "867"
            ],
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CV",
            "defaultName": "Cape Verde",
            "countryCode": "238",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KY",
            "defaultName": "Cayman Islands",
            "countryCode": "1345",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CF",
            "defaultName": "Central African Rep.",
            "countryCode": "236",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TD",
            "defaultName": "Chad",
            "countryCode": "235",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CL",
            "defaultName": "Chile",
            "countryCode": "56",
            "patterns": [
                "X XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CN",
            "defaultName": "China",
            "countryCode": "86",
            "patterns": [
                "XXX XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CO",
            "defaultName": "Colombia",
            "countryCode": "57",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KM",
            "defaultName": "Comoros",
            "countryCode": "269",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CD",
            "defaultName": "Congo (Dem. Rep.)",
            "countryCode": "243",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CG",
            "defaultName": "Congo (Rep.)",
            "countryCode": "242",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CK",
            "defaultName": "Cook Islands",
            "countryCode": "682"
        },
        {
            "isHidden": false,
            "iso2": "CR",
            "defaultName": "Costa Rica",
            "countryCode": "506",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CI",
            "defaultName": "Côte d'Ivoire",
            "countryCode": "225",
            "patterns": [
                "XX XX XX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "HR",
            "defaultName": "Croatia",
            "countryCode": "385",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CU",
            "defaultName": "Cuba",
            "countryCode": "53",
            "patterns": [
                "X XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CW",
            "defaultName": "Curaçao",
            "countryCode": "599",
            "prefixes": [
                "9"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CY",
            "defaultName": "Cyprus",
            "countryCode": "357",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CZ",
            "defaultName": "Czech Republic",
            "countryCode": "420",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DK",
            "defaultName": "Denmark",
            "countryCode": "45",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IO",
            "defaultName": "Diego Garcia",
            "countryCode": "246",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DJ",
            "defaultName": "Djibouti",
            "countryCode": "253",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DM",
            "defaultName": "Dominica",
            "countryCode": "1767",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DO",
            "defaultName": "Dominican Rep.",
            "countryCode": "1809",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DO",
            "defaultName": "Dominican Rep.",
            "countryCode": "1829",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DO",
            "defaultName": "Dominican Rep.",
            "countryCode": "1849",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "EC",
            "defaultName": "Ecuador",
            "countryCode": "593",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "EG",
            "defaultName": "Egypt",
            "countryCode": "20",
            "patterns": [
                "XX XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SV",
            "defaultName": "El Salvador",
            "countryCode": "503",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GQ",
            "defaultName": "Equatorial Guinea",
            "countryCode": "240",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ER",
            "defaultName": "Eritrea",
            "countryCode": "291",
            "patterns": [
                "X XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "EE",
            "defaultName": "Estonia",
            "countryCode": "372",
            "patterns": [
                "XXXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SZ",
            "defaultName": "Eswatini",
            "countryCode": "268",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ET",
            "defaultName": "Ethiopia",
            "countryCode": "251",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "FK",
            "defaultName": "Falkland Islands",
            "countryCode": "500"
        },
        {
            "isHidden": false,
            "iso2": "FO",
            "defaultName": "Faroe Islands",
            "countryCode": "298",
            "patterns": [
                "XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "FJ",
            "defaultName": "Fiji",
            "countryCode": "679",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "FI",
            "defaultName": "Finland",
            "countryCode": "358"
        },
        {
            "isHidden": false,
            "iso2": "FR",
            "defaultName": "France",
            "countryCode": "33",
            "patterns": [
                "X XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GF",
            "defaultName": "French Guiana",
            "countryCode": "594",
            "patterns": [
                "XXX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PF",
            "defaultName": "French Polynesia",
            "countryCode": "689"
        },
        {
            "isHidden": false,
            "iso2": "GA",
            "defaultName": "Gabon",
            "countryCode": "241",
            "patterns": [
                "X XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GM",
            "defaultName": "Gambia",
            "countryCode": "220",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GE",
            "defaultName": "Georgia",
            "countryCode": "995",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DE",
            "defaultName": "Germany",
            "countryCode": "49"
        },
        {
            "isHidden": false,
            "iso2": "GH",
            "defaultName": "Ghana",
            "countryCode": "233",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GI",
            "defaultName": "Gibraltar",
            "countryCode": "350",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GR",
            "defaultName": "Greece",
            "countryCode": "30",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GL",
            "defaultName": "Greenland",
            "countryCode": "299",
            "patterns": [
                "XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GD",
            "defaultName": "Grenada",
            "countryCode": "1473",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GP",
            "defaultName": "Guadeloupe",
            "countryCode": "590",
            "patterns": [
                "XXX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GU",
            "defaultName": "Guam",
            "countryCode": "1671",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GT",
            "defaultName": "Guatemala",
            "countryCode": "502",
            "patterns": [
                "X XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GN",
            "defaultName": "Guinea",
            "countryCode": "224",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GW",
            "defaultName": "Guinea-Bissau",
            "countryCode": "245",
            "patterns": [
                "XXX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GY",
            "defaultName": "Guyana",
            "countryCode": "592",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "HT",
            "defaultName": "Haiti",
            "countryCode": "509",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "HN",
            "defaultName": "Honduras",
            "countryCode": "504",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "HK",
            "defaultName": "Hong Kong",
            "countryCode": "852",
            "patterns": [
                "X XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "HU",
            "defaultName": "Hungary",
            "countryCode": "36",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IS",
            "defaultName": "Iceland",
            "countryCode": "354",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IN",
            "defaultName": "India",
            "countryCode": "91",
            "patterns": [
                "XXXXX XXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ID",
            "defaultName": "Indonesia",
            "countryCode": "62",
            "patterns": [
                "XXX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IR",
            "defaultName": "Iran",
            "countryCode": "98",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IQ",
            "defaultName": "Iraq",
            "countryCode": "964",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IE",
            "defaultName": "Ireland",
            "countryCode": "353",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IL",
            "defaultName": "Israel",
            "countryCode": "972",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IT",
            "defaultName": "Italy",
            "countryCode": "39",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "JM",
            "defaultName": "Jamaica",
            "countryCode": "1876",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "JP",
            "defaultName": "Japan",
            "countryCode": "81",
            "patterns": [
                "XX XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "JO",
            "defaultName": "Jordan",
            "countryCode": "962",
            "patterns": [
                "X XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KZ",
            "defaultName": "Kazakhstan",
            "countryCode": "7",
            "prefixes": [
                "6",
                "7"
            ],
            "patterns": [
                "XXX XXX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KE",
            "defaultName": "Kenya",
            "countryCode": "254",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KI",
            "defaultName": "Kiribati",
            "countryCode": "686",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "XK",
            "defaultName": "Kosovo",
            "countryCode": "383",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KW",
            "defaultName": "Kuwait",
            "countryCode": "965",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KG",
            "defaultName": "Kyrgyzstan",
            "countryCode": "996",
            "patterns": [
                "XXX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LA",
            "defaultName": "Laos",
            "countryCode": "856",
            "patterns": [
                "XX XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LV",
            "defaultName": "Latvia",
            "countryCode": "371",
            "patterns": [
                "XXX XXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LB",
            "defaultName": "Lebanon",
            "countryCode": "961"
        },
        {
            "isHidden": false,
            "iso2": "LS",
            "defaultName": "Lesotho",
            "countryCode": "266",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LR",
            "defaultName": "Liberia",
            "countryCode": "231",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LY",
            "defaultName": "Libya",
            "countryCode": "218",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LI",
            "defaultName": "Liechtenstein",
            "countryCode": "423",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LT",
            "defaultName": "Lithuania",
            "countryCode": "370",
            "patterns": [
                "XXX XXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LU",
            "defaultName": "Luxembourg",
            "countryCode": "352",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MO",
            "defaultName": "Macau",
            "countryCode": "853",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MG",
            "defaultName": "Madagascar",
            "countryCode": "261",
            "patterns": [
                "XX XX XXX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MW",
            "defaultName": "Malawi",
            "countryCode": "265",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MY",
            "defaultName": "Malaysia",
            "countryCode": "60"
        },
        {
            "isHidden": false,
            "iso2": "MV",
            "defaultName": "Maldives",
            "countryCode": "960",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ML",
            "defaultName": "Mali",
            "countryCode": "223",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MT",
            "defaultName": "Malta",
            "countryCode": "356",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MH",
            "defaultName": "Marshall Islands",
            "countryCode": "692"
        },
        {
            "isHidden": false,
            "iso2": "MQ",
            "defaultName": "Martinique",
            "countryCode": "596"
        },
        {
            "isHidden": false,
            "iso2": "MR",
            "defaultName": "Mauritania",
            "countryCode": "222",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MU",
            "defaultName": "Mauritius",
            "countryCode": "230",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MX",
            "defaultName": "Mexico",
            "countryCode": "52"
        },
        {
            "isHidden": false,
            "iso2": "FM",
            "defaultName": "Micronesia",
            "countryCode": "691"
        },
        {
            "isHidden": false,
            "iso2": "MD",
            "defaultName": "Moldova",
            "countryCode": "373",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MC",
            "defaultName": "Monaco",
            "countryCode": "377",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MN",
            "defaultName": "Mongolia",
            "countryCode": "976",
            "patterns": [
                "XX XX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ME",
            "defaultName": "Montenegro",
            "countryCode": "382"
        },
        {
            "isHidden": false,
            "iso2": "MS",
            "defaultName": "Montserrat",
            "countryCode": "1664",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MA",
            "defaultName": "Morocco",
            "countryCode": "212",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MZ",
            "defaultName": "Mozambique",
            "countryCode": "258",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MM",
            "defaultName": "Myanmar",
            "countryCode": "95"
        },
        {
            "isHidden": false,
            "iso2": "NA",
            "defaultName": "Namibia",
            "countryCode": "264",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NR",
            "defaultName": "Nauru",
            "countryCode": "674"
        },
        {
            "isHidden": false,
            "iso2": "NP",
            "defaultName": "Nepal",
            "countryCode": "977",
            "patterns": [
                "XX XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NL",
            "defaultName": "Netherlands",
            "countryCode": "31",
            "patterns": [
                "X XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NC",
            "defaultName": "New Caledonia",
            "countryCode": "687"
        },
        {
            "isHidden": false,
            "iso2": "NZ",
            "defaultName": "New Zealand",
            "countryCode": "64",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NI",
            "defaultName": "Nicaragua",
            "countryCode": "505",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NE",
            "defaultName": "Niger",
            "countryCode": "227",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NG",
            "defaultName": "Nigeria",
            "countryCode": "234",
            "patterns": [
                "XX XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NU",
            "defaultName": "Niue",
            "countryCode": "683"
        },
        {
            "isHidden": false,
            "iso2": "NF",
            "defaultName": "Norfolk Island",
            "countryCode": "672"
        },
        {
            "isHidden": false,
            "iso2": "KP",
            "defaultName": "North Korea",
            "countryCode": "850"
        },
        {
            "isHidden": false,
            "iso2": "MK",
            "defaultName": "North Macedonia",
            "countryCode": "389",
            "patterns": [
                "XX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MP",
            "defaultName": "Northern Mariana Islands",
            "countryCode": "1670",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NO",
            "defaultName": "Norway",
            "countryCode": "47",
            "patterns": [
                "XXX XX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "OM",
            "defaultName": "Oman",
            "countryCode": "968",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PK",
            "defaultName": "Pakistan",
            "countryCode": "92",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PW",
            "defaultName": "Palau",
            "countryCode": "680"
        },
        {
            "isHidden": false,
            "iso2": "PS",
            "defaultName": "Palestine",
            "countryCode": "970",
            "patterns": [
                "XXX XX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PA",
            "defaultName": "Panama",
            "countryCode": "507",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PG",
            "defaultName": "Papua New Guinea",
            "countryCode": "675",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PY",
            "defaultName": "Paraguay",
            "countryCode": "595",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PE",
            "defaultName": "Peru",
            "countryCode": "51",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PH",
            "defaultName": "Philippines",
            "countryCode": "63",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PL",
            "defaultName": "Poland",
            "countryCode": "48",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PT",
            "defaultName": "Portugal",
            "countryCode": "351",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PR",
            "defaultName": "Puerto Rico",
            "countryCode": "1787",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PR",
            "defaultName": "Puerto Rico",
            "countryCode": "1939",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "QA",
            "defaultName": "Qatar",
            "countryCode": "974",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "RE",
            "defaultName": "Réunion",
            "countryCode": "262",
            "patterns": [
                "XXX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "RO",
            "defaultName": "Romania",
            "countryCode": "40",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "RU",
            "defaultName": "Russian Federation",
            "countryCode": "7",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "RW",
            "defaultName": "Rwanda",
            "countryCode": "250",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SH",
            "defaultName": "Saint Helena",
            "countryCode": "247"
        },
        {
            "isHidden": false,
            "iso2": "SH",
            "defaultName": "Saint Helena",
            "countryCode": "290",
            "patterns": [
                "XX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KN",
            "defaultName": "Saint Kitts & Nevis",
            "countryCode": "1869",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LC",
            "defaultName": "Saint Lucia",
            "countryCode": "1758",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PM",
            "defaultName": "Saint Pierre & Miquelon",
            "countryCode": "508"
        },
        {
            "isHidden": false,
            "iso2": "VC",
            "defaultName": "Saint Vincent & the Grenadines",
            "countryCode": "1784",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "WS",
            "defaultName": "Samoa",
            "countryCode": "685"
        },
        {
            "isHidden": false,
            "iso2": "SM",
            "defaultName": "San Marino",
            "countryCode": "378"
        },
        {
            "isHidden": false,
            "iso2": "ST",
            "defaultName": "São Tomé & Príncipe",
            "countryCode": "239",
            "patterns": [
                "XX XXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SA",
            "defaultName": "Saudi Arabia",
            "countryCode": "966",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SN",
            "defaultName": "Senegal",
            "countryCode": "221",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "RS",
            "defaultName": "Serbia",
            "countryCode": "381",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SC",
            "defaultName": "Seychelles",
            "countryCode": "248",
            "patterns": [
                "X XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SL",
            "defaultName": "Sierra Leone",
            "countryCode": "232",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SG",
            "defaultName": "Singapore",
            "countryCode": "65",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SX",
            "defaultName": "Sint Maarten",
            "countryCode": "1721",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SK",
            "defaultName": "Slovakia",
            "countryCode": "421",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SI",
            "defaultName": "Slovenia",
            "countryCode": "386",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SB",
            "defaultName": "Solomon Islands",
            "countryCode": "677"
        },
        {
            "isHidden": false,
            "iso2": "SO",
            "defaultName": "Somalia",
            "countryCode": "252",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ZA",
            "defaultName": "South Africa",
            "countryCode": "27",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KR",
            "defaultName": "South Korea",
            "countryCode": "82",
            "patterns": [
                "XX XXXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SS",
            "defaultName": "South Sudan",
            "countryCode": "211",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ES",
            "defaultName": "Spain",
            "countryCode": "34",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LK",
            "defaultName": "Sri Lanka",
            "countryCode": "94",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SD",
            "defaultName": "Sudan",
            "countryCode": "249",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SR",
            "defaultName": "Suriname",
            "countryCode": "597",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SE",
            "defaultName": "Sweden",
            "countryCode": "46",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CH",
            "defaultName": "Switzerland",
            "countryCode": "41",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SY",
            "defaultName": "Syria",
            "countryCode": "963",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TW",
            "defaultName": "Taiwan",
            "countryCode": "886",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TJ",
            "defaultName": "Tajikistan",
            "countryCode": "992",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TZ",
            "defaultName": "Tanzania",
            "countryCode": "255",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TH",
            "defaultName": "Thailand",
            "countryCode": "66",
            "patterns": [
                "X XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TL",
            "defaultName": "Timor-Leste",
            "countryCode": "670"
        },
        {
            "isHidden": false,
            "iso2": "TG",
            "defaultName": "Togo",
            "countryCode": "228",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TK",
            "defaultName": "Tokelau",
            "countryCode": "690"
        },
        {
            "isHidden": false,
            "iso2": "TO",
            "defaultName": "Tonga",
            "countryCode": "676"
        },
        {
            "isHidden": false,
            "iso2": "TT",
            "defaultName": "Trinidad & Tobago",
            "countryCode": "1868",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TN",
            "defaultName": "Tunisia",
            "countryCode": "216",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TR",
            "defaultName": "Turkey",
            "countryCode": "90",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TM",
            "defaultName": "Turkmenistan",
            "countryCode": "993",
            "patterns": [
                "XX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TC",
            "defaultName": "Turks & Caicos Islands",
            "countryCode": "1649",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TV",
            "defaultName": "Tuvalu",
            "countryCode": "688"
        },
        {
            "isHidden": false,
            "iso2": "UG",
            "defaultName": "Uganda",
            "countryCode": "256",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "UA",
            "defaultName": "Ukraine",
            "countryCode": "380",
            "patterns": [
                "XX XXX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AE",
            "defaultName": "United Arab Emirates",
            "countryCode": "971",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GB",
            "defaultName": "United Kingdom",
            "countryCode": "44",
            "patterns": [
                "XXXX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "UY",
            "defaultName": "Uruguay",
            "countryCode": "598",
            "patterns": [
                "X XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "VI",
            "defaultName": "US Virgin Islands",
            "countryCode": "1340",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "US",
            "defaultName": "USA",
            "countryCode": "1",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "UZ",
            "defaultName": "Uzbekistan",
            "countryCode": "998",
            "patterns": [
                "XX XXX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "VU",
            "defaultName": "Vanuatu",
            "countryCode": "678"
        },
        {
            "isHidden": false,
            "iso2": "VE",
            "defaultName": "Venezuela",
            "countryCode": "58",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "VN",
            "defaultName": "Vietnam",
            "countryCode": "84"
        },
        {
            "isHidden": false,
            "iso2": "WF",
            "defaultName": "Wallis & Futuna",
            "countryCode": "681",
            "patterns": [
                "XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "YE",
            "defaultName": "Yemen",
            "countryCode": "967",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ZM",
            "defaultName": "Zambia",
            "countryCode": "260",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ZW",
            "defaultName": "Zimbabwe",
            "countryCode": "263",
            "patterns": [
                "XX XXX XXXX"
            ]
        }
    ],
    "general": [
        {
            "isHidden": false,
            "iso2": "AF",
            "defaultName": "Afghanistan",
            "countryCode": "93",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AL",
            "defaultName": "Albania",
            "countryCode": "355",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DZ",
            "defaultName": "Algeria",
            "countryCode": "213",
            "patterns": [
                "XXX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AS",
            "defaultName": "American Samoa",
            "countryCode": "1684",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AD",
            "defaultName": "Andorra",
            "countryCode": "376",
            "patterns": [
                "XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AO",
            "defaultName": "Angola",
            "countryCode": "244",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AI",
            "defaultName": "Anguilla",
            "countryCode": "1264",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "FT",
            "defaultName": "Anonymous Numbers",
            "countryCode": "888",
            "patterns": [
                "8 XXX",
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AG",
            "defaultName": "Antigua & Barbuda",
            "countryCode": "1268",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AR",
            "defaultName": "Argentina",
            "countryCode": "54"
        },
        {
            "isHidden": false,
            "iso2": "AM",
            "defaultName": "Armenia",
            "countryCode": "374",
            "patterns": [
                "XX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AW",
            "defaultName": "Aruba",
            "countryCode": "297",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AU",
            "defaultName": "Australia",
            "countryCode": "61",
            "patterns": [
                "X XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AT",
            "defaultName": "Austria",
            "countryCode": "43",
            "patterns": [
                "XXX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AZ",
            "defaultName": "Azerbaijan",
            "countryCode": "994",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BS",
            "defaultName": "Bahamas",
            "countryCode": "1242",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BH",
            "defaultName": "Bahrain",
            "countryCode": "973",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BD",
            "defaultName": "Bangladesh",
            "countryCode": "880",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BB",
            "defaultName": "Barbados",
            "countryCode": "1246",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BY",
            "defaultName": "Belarus",
            "countryCode": "375",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BE",
            "defaultName": "Belgium",
            "countryCode": "32",
            "patterns": [
                "XXX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BZ",
            "defaultName": "Belize",
            "countryCode": "501"
        },
        {
            "isHidden": false,
            "iso2": "BJ",
            "defaultName": "Benin",
            "countryCode": "229",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BM",
            "defaultName": "Bermuda",
            "countryCode": "1441",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BT",
            "defaultName": "Bhutan",
            "countryCode": "975",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BO",
            "defaultName": "Bolivia",
            "countryCode": "591",
            "patterns": [
                "X XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BQ",
            "defaultName": "Bonaire, Sint Eustatius & Saba",
            "countryCode": "599"
        },
        {
            "isHidden": false,
            "iso2": "BA",
            "defaultName": "Bosnia & Herzegovina",
            "countryCode": "387",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BW",
            "defaultName": "Botswana",
            "countryCode": "267",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BR",
            "defaultName": "Brazil",
            "countryCode": "55",
            "patterns": [
                "XX XXXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "VG",
            "defaultName": "British Virgin Islands",
            "countryCode": "1284",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BN",
            "defaultName": "Brunei Darussalam",
            "countryCode": "673",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BG",
            "defaultName": "Bulgaria",
            "countryCode": "359"
        },
        {
            "isHidden": false,
            "iso2": "BF",
            "defaultName": "Burkina Faso",
            "countryCode": "226",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "BI",
            "defaultName": "Burundi",
            "countryCode": "257",
            "patterns": [
                "XX XX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KH",
            "defaultName": "Cambodia",
            "countryCode": "855",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CM",
            "defaultName": "Cameroon",
            "countryCode": "237",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CA",
            "defaultName": "Canada",
            "countryCode": "1",
            "prefixes": [
                "403",
                "587",
                "780",
                "825",
                "236",
                "250",
                "604",
                "672",
                "778",
                "204",
                "431",
                "506",
                "709",
                "902",
                "782",
                "226",
                "249",
                "289",
                "343",
                "365",
                "416",
                "437",
                "519",
                "548",
                "613",
                "647",
                "705",
                "807",
                "905",
                "418",
                "438",
                "450",
                "514",
                "579",
                "581",
                "819",
                "873",
                "306",
                "639",
                "867"
            ],
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CV",
            "defaultName": "Cape Verde",
            "countryCode": "238",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KY",
            "defaultName": "Cayman Islands",
            "countryCode": "1345",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CF",
            "defaultName": "Central African Rep.",
            "countryCode": "236",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TD",
            "defaultName": "Chad",
            "countryCode": "235",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CL",
            "defaultName": "Chile",
            "countryCode": "56",
            "patterns": [
                "X XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CN",
            "defaultName": "China",
            "countryCode": "86",
            "patterns": [
                "XXX XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CO",
            "defaultName": "Colombia",
            "countryCode": "57",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KM",
            "defaultName": "Comoros",
            "countryCode": "269",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CD",
            "defaultName": "Congo (Dem. Rep.)",
            "countryCode": "243",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CG",
            "defaultName": "Congo (Rep.)",
            "countryCode": "242",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CK",
            "defaultName": "Cook Islands",
            "countryCode": "682"
        },
        {
            "isHidden": false,
            "iso2": "CR",
            "defaultName": "Costa Rica",
            "countryCode": "506",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CI",
            "defaultName": "Côte d'Ivoire",
            "countryCode": "225",
            "patterns": [
                "XX XX XX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "HR",
            "defaultName": "Croatia",
            "countryCode": "385",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CU",
            "defaultName": "Cuba",
            "countryCode": "53",
            "patterns": [
                "X XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CW",
            "defaultName": "Curaçao",
            "countryCode": "599",
            "prefixes": [
                "9"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CY",
            "defaultName": "Cyprus",
            "countryCode": "357",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CZ",
            "defaultName": "Czech Republic",
            "countryCode": "420",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DK",
            "defaultName": "Denmark",
            "countryCode": "45",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IO",
            "defaultName": "Diego Garcia",
            "countryCode": "246",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DJ",
            "defaultName": "Djibouti",
            "countryCode": "253",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DM",
            "defaultName": "Dominica",
            "countryCode": "1767",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DO",
            "defaultName": "Dominican Rep.",
            "countryCode": "1809",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "EC",
            "defaultName": "Ecuador",
            "countryCode": "593",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "EG",
            "defaultName": "Egypt",
            "countryCode": "20",
            "patterns": [
                "XX XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SV",
            "defaultName": "El Salvador",
            "countryCode": "503",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GQ",
            "defaultName": "Equatorial Guinea",
            "countryCode": "240",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ER",
            "defaultName": "Eritrea",
            "countryCode": "291",
            "patterns": [
                "X XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "EE",
            "defaultName": "Estonia",
            "countryCode": "372",
            "patterns": [
                "XXXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SZ",
            "defaultName": "Eswatini",
            "countryCode": "268",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ET",
            "defaultName": "Ethiopia",
            "countryCode": "251",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "FK",
            "defaultName": "Falkland Islands",
            "countryCode": "500"
        },
        {
            "isHidden": false,
            "iso2": "FO",
            "defaultName": "Faroe Islands",
            "countryCode": "298",
            "patterns": [
                "XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "FJ",
            "defaultName": "Fiji",
            "countryCode": "679",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "FI",
            "defaultName": "Finland",
            "countryCode": "358"
        },
        {
            "isHidden": false,
            "iso2": "FR",
            "defaultName": "France",
            "countryCode": "33",
            "patterns": [
                "X XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GF",
            "defaultName": "French Guiana",
            "countryCode": "594",
            "patterns": [
                "XXX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PF",
            "defaultName": "French Polynesia",
            "countryCode": "689"
        },
        {
            "isHidden": false,
            "iso2": "GA",
            "defaultName": "Gabon",
            "countryCode": "241",
            "patterns": [
                "X XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GM",
            "defaultName": "Gambia",
            "countryCode": "220",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GE",
            "defaultName": "Georgia",
            "countryCode": "995",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "DE",
            "defaultName": "Germany",
            "countryCode": "49"
        },
        {
            "isHidden": false,
            "iso2": "GH",
            "defaultName": "Ghana",
            "countryCode": "233",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GI",
            "defaultName": "Gibraltar",
            "countryCode": "350",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GR",
            "defaultName": "Greece",
            "countryCode": "30",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GL",
            "defaultName": "Greenland",
            "countryCode": "299",
            "patterns": [
                "XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GD",
            "defaultName": "Grenada",
            "countryCode": "1473",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GP",
            "defaultName": "Guadeloupe",
            "countryCode": "590",
            "patterns": [
                "XXX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GU",
            "defaultName": "Guam",
            "countryCode": "1671",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GT",
            "defaultName": "Guatemala",
            "countryCode": "502",
            "patterns": [
                "X XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GN",
            "defaultName": "Guinea",
            "countryCode": "224",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GW",
            "defaultName": "Guinea-Bissau",
            "countryCode": "245",
            "patterns": [
                "XXX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GY",
            "defaultName": "Guyana",
            "countryCode": "592",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "HT",
            "defaultName": "Haiti",
            "countryCode": "509",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "HN",
            "defaultName": "Honduras",
            "countryCode": "504",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "HK",
            "defaultName": "Hong Kong",
            "countryCode": "852",
            "patterns": [
                "X XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "HU",
            "defaultName": "Hungary",
            "countryCode": "36",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IS",
            "defaultName": "Iceland",
            "countryCode": "354",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IN",
            "defaultName": "India",
            "countryCode": "91",
            "patterns": [
                "XXXXX XXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ID",
            "defaultName": "Indonesia",
            "countryCode": "62",
            "patterns": [
                "XXX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IR",
            "defaultName": "Iran",
            "countryCode": "98",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IQ",
            "defaultName": "Iraq",
            "countryCode": "964",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IE",
            "defaultName": "Ireland",
            "countryCode": "353",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IL",
            "defaultName": "Israel",
            "countryCode": "972",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "IT",
            "defaultName": "Italy",
            "countryCode": "39",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "JM",
            "defaultName": "Jamaica",
            "countryCode": "1876",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "JP",
            "defaultName": "Japan",
            "countryCode": "81",
            "patterns": [
                "XX XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "JO",
            "defaultName": "Jordan",
            "countryCode": "962",
            "patterns": [
                "X XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KZ",
            "defaultName": "Kazakhstan",
            "countryCode": "7",
            "prefixes": [
                "6",
                "7"
            ],
            "patterns": [
                "XXX XXX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KE",
            "defaultName": "Kenya",
            "countryCode": "254",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KI",
            "defaultName": "Kiribati",
            "countryCode": "686",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "XK",
            "defaultName": "Kosovo",
            "countryCode": "383",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KW",
            "defaultName": "Kuwait",
            "countryCode": "965",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KG",
            "defaultName": "Kyrgyzstan",
            "countryCode": "996",
            "patterns": [
                "XXX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LA",
            "defaultName": "Laos",
            "countryCode": "856",
            "patterns": [
                "XX XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LV",
            "defaultName": "Latvia",
            "countryCode": "371",
            "patterns": [
                "XXX XXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LB",
            "defaultName": "Lebanon",
            "countryCode": "961"
        },
        {
            "isHidden": false,
            "iso2": "LS",
            "defaultName": "Lesotho",
            "countryCode": "266",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LR",
            "defaultName": "Liberia",
            "countryCode": "231",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LY",
            "defaultName": "Libya",
            "countryCode": "218",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LI",
            "defaultName": "Liechtenstein",
            "countryCode": "423",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LT",
            "defaultName": "Lithuania",
            "countryCode": "370",
            "patterns": [
                "XXX XXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LU",
            "defaultName": "Luxembourg",
            "countryCode": "352",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MO",
            "defaultName": "Macau",
            "countryCode": "853",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MG",
            "defaultName": "Madagascar",
            "countryCode": "261",
            "patterns": [
                "XX XX XXX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MW",
            "defaultName": "Malawi",
            "countryCode": "265",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MY",
            "defaultName": "Malaysia",
            "countryCode": "60"
        },
        {
            "isHidden": false,
            "iso2": "MV",
            "defaultName": "Maldives",
            "countryCode": "960",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ML",
            "defaultName": "Mali",
            "countryCode": "223",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MT",
            "defaultName": "Malta",
            "countryCode": "356",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MH",
            "defaultName": "Marshall Islands",
            "countryCode": "692"
        },
        {
            "isHidden": false,
            "iso2": "MQ",
            "defaultName": "Martinique",
            "countryCode": "596"
        },
        {
            "isHidden": false,
            "iso2": "MR",
            "defaultName": "Mauritania",
            "countryCode": "222",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MU",
            "defaultName": "Mauritius",
            "countryCode": "230",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MX",
            "defaultName": "Mexico",
            "countryCode": "52"
        },
        {
            "isHidden": false,
            "iso2": "FM",
            "defaultName": "Micronesia",
            "countryCode": "691"
        },
        {
            "isHidden": false,
            "iso2": "MD",
            "defaultName": "Moldova",
            "countryCode": "373",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MC",
            "defaultName": "Monaco",
            "countryCode": "377",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MN",
            "defaultName": "Mongolia",
            "countryCode": "976",
            "patterns": [
                "XX XX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ME",
            "defaultName": "Montenegro",
            "countryCode": "382"
        },
        {
            "isHidden": false,
            "iso2": "MS",
            "defaultName": "Montserrat",
            "countryCode": "1664",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MA",
            "defaultName": "Morocco",
            "countryCode": "212",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MZ",
            "defaultName": "Mozambique",
            "countryCode": "258",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MM",
            "defaultName": "Myanmar",
            "countryCode": "95"
        },
        {
            "isHidden": false,
            "iso2": "NA",
            "defaultName": "Namibia",
            "countryCode": "264",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NR",
            "defaultName": "Nauru",
            "countryCode": "674"
        },
        {
            "isHidden": false,
            "iso2": "NP",
            "defaultName": "Nepal",
            "countryCode": "977",
            "patterns": [
                "XX XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NL",
            "defaultName": "Netherlands",
            "countryCode": "31",
            "patterns": [
                "X XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NC",
            "defaultName": "New Caledonia",
            "countryCode": "687"
        },
        {
            "isHidden": false,
            "iso2": "NZ",
            "defaultName": "New Zealand",
            "countryCode": "64",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NI",
            "defaultName": "Nicaragua",
            "countryCode": "505",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NE",
            "defaultName": "Niger",
            "countryCode": "227",
            "patterns": [
                "XX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NG",
            "defaultName": "Nigeria",
            "countryCode": "234",
            "patterns": [
                "XX XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NU",
            "defaultName": "Niue",
            "countryCode": "683"
        },
        {
            "isHidden": false,
            "iso2": "NF",
            "defaultName": "Norfolk Island",
            "countryCode": "672"
        },
        {
            "isHidden": false,
            "iso2": "KP",
            "defaultName": "North Korea",
            "countryCode": "850"
        },
        {
            "isHidden": false,
            "iso2": "MK",
            "defaultName": "North Macedonia",
            "countryCode": "389",
            "patterns": [
                "XX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "MP",
            "defaultName": "Northern Mariana Islands",
            "countryCode": "1670",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "NO",
            "defaultName": "Norway",
            "countryCode": "47",
            "patterns": [
                "XXX XX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "OM",
            "defaultName": "Oman",
            "countryCode": "968",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PK",
            "defaultName": "Pakistan",
            "countryCode": "92",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PW",
            "defaultName": "Palau",
            "countryCode": "680"
        },
        {
            "isHidden": false,
            "iso2": "PS",
            "defaultName": "Palestine",
            "countryCode": "970",
            "patterns": [
                "XXX XX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PA",
            "defaultName": "Panama",
            "countryCode": "507",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PG",
            "defaultName": "Papua New Guinea",
            "countryCode": "675",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PY",
            "defaultName": "Paraguay",
            "countryCode": "595",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PE",
            "defaultName": "Peru",
            "countryCode": "51",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PH",
            "defaultName": "Philippines",
            "countryCode": "63",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PL",
            "defaultName": "Poland",
            "countryCode": "48",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PT",
            "defaultName": "Portugal",
            "countryCode": "351",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PR",
            "defaultName": "Puerto Rico",
            "countryCode": "1787",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "QA",
            "defaultName": "Qatar",
            "countryCode": "974",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "RE",
            "defaultName": "Réunion",
            "countryCode": "262",
            "patterns": [
                "XXX XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "RO",
            "defaultName": "Romania",
            "countryCode": "40",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "RU",
            "defaultName": "Russian Federation",
            "countryCode": "7",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "RW",
            "defaultName": "Rwanda",
            "countryCode": "250",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SH",
            "defaultName": "Saint Helena",
            "countryCode": "247"
        },
        {
            "isHidden": false,
            "iso2": "KN",
            "defaultName": "Saint Kitts & Nevis",
            "countryCode": "1869",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LC",
            "defaultName": "Saint Lucia",
            "countryCode": "1758",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "PM",
            "defaultName": "Saint Pierre & Miquelon",
            "countryCode": "508"
        },
        {
            "isHidden": false,
            "iso2": "VC",
            "defaultName": "Saint Vincent & the Grenadines",
            "countryCode": "1784",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "WS",
            "defaultName": "Samoa",
            "countryCode": "685"
        },
        {
            "isHidden": false,
            "iso2": "SM",
            "defaultName": "San Marino",
            "countryCode": "378"
        },
        {
            "isHidden": false,
            "iso2": "ST",
            "defaultName": "São Tomé & Príncipe",
            "countryCode": "239",
            "patterns": [
                "XX XXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SA",
            "defaultName": "Saudi Arabia",
            "countryCode": "966",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SN",
            "defaultName": "Senegal",
            "countryCode": "221",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "RS",
            "defaultName": "Serbia",
            "countryCode": "381",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SC",
            "defaultName": "Seychelles",
            "countryCode": "248",
            "patterns": [
                "X XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SL",
            "defaultName": "Sierra Leone",
            "countryCode": "232",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SG",
            "defaultName": "Singapore",
            "countryCode": "65",
            "patterns": [
                "XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SX",
            "defaultName": "Sint Maarten",
            "countryCode": "1721",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SK",
            "defaultName": "Slovakia",
            "countryCode": "421",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SI",
            "defaultName": "Slovenia",
            "countryCode": "386",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SB",
            "defaultName": "Solomon Islands",
            "countryCode": "677"
        },
        {
            "isHidden": false,
            "iso2": "SO",
            "defaultName": "Somalia",
            "countryCode": "252",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ZA",
            "defaultName": "South Africa",
            "countryCode": "27",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "KR",
            "defaultName": "South Korea",
            "countryCode": "82",
            "patterns": [
                "XX XXXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SS",
            "defaultName": "South Sudan",
            "countryCode": "211",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ES",
            "defaultName": "Spain",
            "countryCode": "34",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "LK",
            "defaultName": "Sri Lanka",
            "countryCode": "94",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SD",
            "defaultName": "Sudan",
            "countryCode": "249",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SR",
            "defaultName": "Suriname",
            "countryCode": "597",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SE",
            "defaultName": "Sweden",
            "countryCode": "46",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "CH",
            "defaultName": "Switzerland",
            "countryCode": "41",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "SY",
            "defaultName": "Syria",
            "countryCode": "963",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TW",
            "defaultName": "Taiwan",
            "countryCode": "886",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TJ",
            "defaultName": "Tajikistan",
            "countryCode": "992",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TZ",
            "defaultName": "Tanzania",
            "countryCode": "255",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TH",
            "defaultName": "Thailand",
            "countryCode": "66",
            "patterns": [
                "X XXXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TL",
            "defaultName": "Timor-Leste",
            "countryCode": "670"
        },
        {
            "isHidden": false,
            "iso2": "TG",
            "defaultName": "Togo",
            "countryCode": "228",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TK",
            "defaultName": "Tokelau",
            "countryCode": "690"
        },
        {
            "isHidden": false,
            "iso2": "TO",
            "defaultName": "Tonga",
            "countryCode": "676"
        },
        {
            "isHidden": false,
            "iso2": "TT",
            "defaultName": "Trinidad & Tobago",
            "countryCode": "1868",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TN",
            "defaultName": "Tunisia",
            "countryCode": "216",
            "patterns": [
                "XX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TR",
            "defaultName": "Turkey",
            "countryCode": "90",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TM",
            "defaultName": "Turkmenistan",
            "countryCode": "993",
            "patterns": [
                "XX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TC",
            "defaultName": "Turks & Caicos Islands",
            "countryCode": "1649",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "TV",
            "defaultName": "Tuvalu",
            "countryCode": "688"
        },
        {
            "isHidden": false,
            "iso2": "UG",
            "defaultName": "Uganda",
            "countryCode": "256",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "UA",
            "defaultName": "Ukraine",
            "countryCode": "380",
            "patterns": [
                "XX XXX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "AE",
            "defaultName": "United Arab Emirates",
            "countryCode": "971",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "GB",
            "defaultName": "United Kingdom",
            "countryCode": "44",
            "patterns": [
                "XXXX XXXXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "UY",
            "defaultName": "Uruguay",
            "countryCode": "598",
            "patterns": [
                "X XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "VI",
            "defaultName": "US Virgin Islands",
            "countryCode": "1340",
            "patterns": [
                "XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "US",
            "defaultName": "USA",
            "countryCode": "1",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "UZ",
            "defaultName": "Uzbekistan",
            "countryCode": "998",
            "patterns": [
                "XX XXX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "VU",
            "defaultName": "Vanuatu",
            "countryCode": "678"
        },
        {
            "isHidden": false,
            "iso2": "VE",
            "defaultName": "Venezuela",
            "countryCode": "58",
            "patterns": [
                "XXX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "VN",
            "defaultName": "Vietnam",
            "countryCode": "84"
        },
        {
            "isHidden": false,
            "iso2": "WF",
            "defaultName": "Wallis & Futuna",
            "countryCode": "681",
            "patterns": [
                "XX XX XX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "YE",
            "defaultName": "Yemen",
            "countryCode": "967",
            "patterns": [
                "XXX XXX XXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ZM",
            "defaultName": "Zambia",
            "countryCode": "260",
            "patterns": [
                "XX XXX XXXX"
            ]
        },
        {
            "isHidden": false,
            "iso2": "ZW",
            "defaultName": "Zimbabwe",
            "countryCode": "263",
            "patterns": [
                "XX XXX XXXX"
            ]
        }
    ]
}